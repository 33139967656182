import React from 'react';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  RadioGroup,
  TextField,
  Drawer,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputLabel,
  Input,
  InputAdornment,
  Checkbox,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { ApplicationFilterType, RMType } from '../../redux-store/types/api-types';
import { filterApplicationTypes, ModeOfHoldingTypes } from '../../utils/constant';
import { Distributor, getRMsList } from '../../redux-store/actions';
import { LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import KeyboardArrowRightSharpIcon from '@mui/icons-material/KeyboardArrowRightSharp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchSharpIcon from '@mui/icons-material/SearchSharp';

type FiltersDropDownProps = {
  filters: ApplicationFilterType;
  anchorEl: boolean;
  onFiltersChange: (value: ApplicationFilterType) => void;
  handleClose: () => void;
  distributors: Distributor[];
  loader?: boolean;
};

const AccordionFilter = ({
  filterName = '',
  children,
  expanded,
  handleChange,
}: {
  filterName: string;
  children: JSX.Element;
  expanded: boolean;
  handleChange: (
    event: React.SyntheticEvent<Element, Event>,
    expanded: boolean,
    filterName: string
  ) => void;
}): JSX.Element => {
  return (
    <Accordion
      sx={{
        '&.MuiAccordion-root': {
          boxShadow: 'none',
        },
      }}
      expanded={expanded}
      onChange={(event, expanded) => handleChange(event, expanded, filterName)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <FormLabel component="legend">{filterName}</FormLabel>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export const FilterDatePicker = ({
  value,
  onChange,
  placeholder = '',
  errorText = '',
}: {
  value: Date;
  onChange: (date: Date | null) => void;
  placeholder: string;
  errorText?: string;
}): JSX.Element => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FormControl variant="outlined" fullWidth>
        <MobileDatePicker
          components={{ OpenPickerIcon: KeyboardArrowRightSharpIcon }}
          value={value}
          maxDate={new Date()}
          inputFormat="dd/MM/yyyy"
          onChange={onChange}
          renderInput={(params) => (
            <TextField
              fullWidth
              id="date-picker"
              placeholder={placeholder}
              {...params}
              sx={{
                '& .MuiInputBase-root': {
                  height: '44px',
                },
              }}
            />
          )}
          disableCloseOnSelect={false}
          showToolbar={false}
        />
        {errorText && (
          <FormHelperText error sx={{ marginLeft: 'unset' }}>
            {errorText}
          </FormHelperText>
        )}
      </FormControl>
    </LocalizationProvider>
  );
};

const Inival = (role: string) => {
  return {
    applicationType: [],
    modeOfHolding: [],
    status: [],
    distributorId: [],
    applicationSentBack: null,
  };
};

export const initialFilters = (
  role: string,
  storeStatus: string,
  storeApplicationType: string,
  storeModeOfHolding: string,
  storeDistributorId: string
): ApplicationFilterType => {
  function isApplicationWithCompleted(storeStatus: string): string[] {
    const completedIndex = storeStatus.indexOf('completed');
    const completed_signed = storeStatus.slice(
      completedIndex,
      completedIndex + 'completed,signed'.length
    );
    const statusArray = storeStatus.split(',');
    const filteredArray = statusArray.filter((item) => !['completed', 'signed'].includes(item));
    return filteredArray.concat(completed_signed);
  }
  const status = storeStatus
    ? storeStatus.indexOf('completed') > -1
      ? isApplicationWithCompleted(storeStatus)
      : storeStatus.split(',')
    : [];

  return {
    applicationType: storeApplicationType ? storeApplicationType.split(',') : [],
    modeOfHolding: storeModeOfHolding ? storeModeOfHolding.split(',') : [],
    status: status,
    distributorId: storeDistributorId ? storeDistributorId.split(',') : [],
  };
};

const SearchComponent = ({
  search,
  setSearch,
}: {
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}): JSX.Element => {
  return (
    <FormControl variant="standard" sx={{ display: 'block' }}>
      <InputLabel
        htmlFor="input-with-icon-adornment"
        sx={{ '&.Mui-focused': { color: 'text.primary' } }}>
        Search
      </InputLabel>
      <Input
        id="input-with-icon-adornment"
        value={search}
        autoComplete={'off'}
        onChange={({ target: { value } }) => setSearch(value)}
        endAdornment={
          <InputAdornment position="end">
            <IconButton>
              <SearchSharpIcon sx={{ color: 'primary.main' }} />
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

export const FilterDropdown = ({
  filters,
  anchorEl,
  onFiltersChange,
  distributors = [],
  handleClose,
  loader,
}: FiltersDropDownProps): JSX.Element => {
  const [distributorSearch, setDistributorSearch] = useState('');
  const {
    page,
    status: storeStatus,
    applicationType: storeApplicationType,
    modeOfHolding: storeModeOfHolding,
    distributorId: storeDistributorId,
  } = useSelector((store: RootStateType) => store.paramsObj);
  const [filterExpanded, setExpanded] = useState<boolean | string>(false);
  const { role } = useSelector((store: RootStateType) => store.auth);
  const [modalState, setModalState] = useState(
    initialFilters(role, storeStatus, storeApplicationType, storeModeOfHolding, storeDistributorId)
  );

  useEffect(() => {
    setModalState(filters);
  }, [filters, anchorEl]);

  const _handleModalState = () => {
    handleClose();
    setModalState(
      initialFilters(
        role,
        storeStatus,
        storeApplicationType,
        storeModeOfHolding,
        storeDistributorId
      )
    );
  };

  const _handleApplyFilters = () => {
    onFiltersChange(modalState);
    _handleModalState();
  };

  const filterApplicatiostatusTypes = [
    { key: 'draft', value: 'Draft' },
    { key: 'draft_send_back', value: 'Draft(Send Back)' },
    { key: 'sent_to_applicant1', value: 'Sent to 1st applicant' },
    { key: 'approved_by_applicant1', value: 'Approved by 1st Applicant' },
    { key: 'approved_by_applicant2', value: 'Approved by 2nd Applicant' },
    { key: 'approved_by_applicant3', value: 'Approved by 3rd Applicant' },
    { key: 'approved_by_fundmanager', value: 'Approved by investment manager' },
    { key: 'invitationexpired', value: 'Invitation Expired' },
    { key: 'completed,signed', value: 'Completed' },
    { key: 'rejected', value: 'Rejected' },
    {
      key: 'sent_to_poa_approver',
      value: role === 'poaapprover' ? 'Pending' : 'Sent to POA approver',
    },
    {
      key: 'sent_to_amc_approver',
      value: role === 'amcapprover' ? 'Pending' : 'Sent to AIF approver',
    },
    { key: 'sent_to_authorised_signatories', value: 'Sent to authorised signatories' },
    { key: 'approved_by_authorised_signatories', value: 'Approved by authorised signatories' },
  ];

  const _handleClearFilters = () => {
    onFiltersChange(Inival(role));
    handleClose();
  };

  const _handleSelection = (item: string[] | string | number, key: string | any) => {
    const index = (modalState[key] as string[]).indexOf(item as string);
    if (index === -1) {
      setModalState({
        ...modalState,
        [key]: [...((modalState[key] as string[]) || []), item] as string[],
      });
    } else {
      setModalState({
        ...modalState,
        [key]: (modalState[key] as string[]).filter((status: any) => status != (item as string)),
      });
    }
  };

  const handleAccordionExpanded = (
    event: React.SyntheticEvent<Element, Event>,
    isExpanded: boolean,
    panel: string
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  const distributorFilter = useMemo(() => {
    const filteredDistributors = distributorSearch
      ? distributors.filter((distributor) =>
          distributor.name.trim().toLowerCase().includes(distributorSearch.trim().toLowerCase())
        )
      : distributors;
    return (
      <>
        <SearchComponent search={distributorSearch} setSearch={setDistributorSearch} />
        {loader ? (
          <Typography>{'Loading...'}</Typography>
        ) : (
          <>
            {filteredDistributors.length ? (
              <Box sx={{ mt: 2 }}>
                <RadioGroup aria-label="Distributor Name" defaultValue="" name="Distributor Name">
                  {filteredDistributors.map((distributor: Distributor) => (
                    <FormControlLabel
                      key={distributor.id}
                      value={distributor.id.toString()}
                      onChange={() => _handleSelection(distributor.id, 'distributorId')}
                      control={
                        <Checkbox
                          size="small"
                          checked={modalState.distributorId
                            .map((dis) => dis.toString())
                            .includes(distributor.id.toString())}
                        />
                      }
                      label={distributor.name}
                      title={distributor.name}
                    />
                  ))}
                </RadioGroup>
              </Box>
            ) : (
              <Typography>{'No Results found'}</Typography>
            )}
          </>
        )}
      </>
    );
  }, [distributorSearch, distributors, loader, modalState]);

  return (
    <Box>
      <Drawer anchor={'right'} open={anchorEl} onClose={_handleModalState}>
        <FormControl
          component="fieldset"
          sx={{
            width: '100%',
            position: 'relative',
            p: { xs: 1, sm: 2 },
            pb: 0,
            '.MuiFormLabel-root ': { color: 'primary.main', fontWeight: 600, mb: 0.5 },
            '.MuiFormControlLabel-root': {
              ml: '-2px',
              mb: 0.75,
              width: '100%',
              alignItems: 'flex-start',
              '.MuiTypography-root': {
                color: 'text.primary',
                fontWeight: 500,
                fontSize: 14,
                overflow: { xs: 'unset', md: 'hidden' },
                whiteSpace: { xs: 'unset', md: 'nowrap' },
                textOverflow: { xs: 'unset', md: 'ellipsis' },
                wordBreak: { xs: 'break-word', md: 'unset' },
              },
              '.MuiRadio-root,.MuiCheckbox-root ,.Mui-checked': { color: 'text.primary' },
              '.MuiRadio-root,.MuiCheckbox-root ': { p: 0, mr: 0.5 },
            },
            '.MuiAccordionDetails-root': {
              paddingTop: 0,
            },
            '.MuiAccordionSummary-content.Mui-expanded': {
              marginBottom: '10px',
            },
          }}>
          <Box>
            <IconButton sx={{ float: 'right', p: 0 }} onClick={_handleModalState}>
              <CancelOutlinedIcon sx={{ color: '#EA5167' }} fontSize="large" />
            </IconButton>
          </Box>
          <Box>
            <AccordionFilter
              filterName={'Status'}
              expanded={filterExpanded === 'Status'}
              handleChange={(e, expanded, filterName) =>
                handleAccordionExpanded(e, expanded, filterName)
              }>
              <RadioGroup aria-label="status" defaultValue="" name="status">
                {filterApplicatiostatusTypes
                  .filter((obj) => {
                    if (role === 'amcapprover') {
                      return (
                        !['draft', 'draft_send_back'].includes(obj.key) &&
                        obj.key !== 'sent_to_poa_approver'
                      );
                    }
                    if (role === 'poaapprover') {
                      return !['draft', 'draft_send_back'].includes(obj.key);
                    }
                    return obj;
                  })
                  .map((type: { key: string; value: string }) => (
                    <FormControlLabel
                      key={type.key}
                      value={type.key}
                      onChange={() => _handleSelection(type.key, 'status')}
                      control={
                        <Checkbox size="small" checked={modalState.status.includes(type.key)} />
                      }
                      label={type.value}
                      title={type.value}
                    />
                  ))}
              </RadioGroup>
            </AccordionFilter>

            <AccordionFilter
              filterName={'Distributor Name'}
              expanded={filterExpanded === 'Distributor Name'}
              handleChange={(e, expanded, filterName) =>
                handleAccordionExpanded(e, expanded, filterName)
              }>
              {distributorFilter}
            </AccordionFilter>

            <AccordionFilter
              filterName={'Mode of Holding'}
              expanded={filterExpanded === 'Mode of Holding'}
              handleChange={(e, expanded, filterName) =>
                handleAccordionExpanded(e, expanded, filterName)
              }>
              <RadioGroup aria-label="modeOfHolding" defaultValue="" name="modeOfHolding">
                {ModeOfHoldingTypes.map((type: { key: string; value: string }) => (
                  <FormControlLabel
                    key={type.key}
                    value={type.key}
                    onChange={() => _handleSelection(type.key, 'modeOfHolding')}
                    control={
                      <Checkbox
                        size="small"
                        checked={modalState.modeOfHolding.includes(type.key)}
                      />
                    }
                    label={type.value}
                    title={type.value}
                  />
                ))}
              </RadioGroup>
            </AccordionFilter>
            <AccordionFilter
              filterName={'Investor Type'}
              expanded={filterExpanded === 'Investor Type'}
              handleChange={(e, expanded, filterName) =>
                handleAccordionExpanded(e, expanded, filterName)
              }>
              <RadioGroup aria-label="applicationType" defaultValue="" name="applicationType">
                {filterApplicationTypes.map((type: { key: string; value: string }) => (
                  <FormControlLabel
                    key={type.key}
                    value={type.key}
                    onChange={() => _handleSelection(type.key, 'applicationType')}
                    control={
                      <Checkbox
                        size="small"
                        checked={modalState.applicationType.includes(type.key)}
                      />
                    }
                    label={type.value}
                    title={type.value}
                  />
                ))}
              </RadioGroup>
            </AccordionFilter>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 2,
              mt: 2,
              '& .MuiButton-root': {
                fontSize: 16,
                mx: 1,
                px: 3.5,
                py: 0.5,
              },
            }}>
            <Button
              type="submit"
              sx={{
                color: 'common.white',
                '&, :hover': { bgcolor: 'primary.main' },
              }}
              onClick={_handleApplyFilters}>
              Apply Filters
            </Button>
            <Button
              type="reset"
              sx={{
                color: 'text.primary',
                border: '1px solid #1F4173',
                '&, :hover': { bgcolor: 'common.white' },
              }}
              onClick={_handleClearFilters}>
              Clear Filters
            </Button>
          </Box>
        </FormControl>
      </Drawer>
    </Box>
  );
};
