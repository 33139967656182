import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { ApplicationProps, Groups, GroupSignatories } from '../../redux-store/types/api-types';
import { WebViewDataRow } from '../DataTable';
import { poaAuthorisedheaders } from '../NonIndividualInvestor/Authorised';
import { checkIfApplicationIsNonIndividualPOA } from '../../utils/utilityFunctions';

const headers = [
  {
    header: 'Name',
    label: 'name',
    valueGetter: (row: GroupSignatories) => row.name || 'N/A',
  },
  {
    header: 'PAN',
    valueGetter: (row: GroupSignatories) => row.pan.toUpperCase() || 'N/A',
  },
  {
    header: 'Email ID',
    valueGetter: (row: GroupSignatories) => row.email || 'N/A',
  },
  {
    header: 'Designation',
    valueGetter: (row: GroupSignatories) => row.designation || 'N/A',
  },
  {
    header: 'Mobile Number',
    valueGetter: (row: GroupSignatories) =>
      row.mobile && row.countryCode ? row.countryCode + '-' + row.mobile : row.mobile || 'N/A',
  },
];

export default function AuthorizedSignatories({
  application,
}: {
  application: ApplicationProps;
}): JSX.Element {
  const { groups = [], poaauthorisedsignatories = [], requiredAuthorisedSignatory } = application;
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Authorised Signatories
            </Typography>
            {((!checkIfApplicationIsNonIndividualPOA(application) && groups.length === 0) ||
              (checkIfApplicationIsNonIndividualPOA(application) &&
                poaauthorisedsignatories.length === 0)) && (
              <Grid
                xs={12}
                sm={12}
                sx={{
                  fontSize: 18,
                  fontWeight: 600,
                  color: 'common.black',
                  textAlign: 'center',
                  my: 3,
                }}>
                No Authorised Signatories
              </Grid>
            )}
            {checkIfApplicationIsNonIndividualPOA(application) &&
            poaauthorisedsignatories.length > 0 ? (
              <>
                <Grid container pt={3}>
                  <Typography>
                    Total No. of required authorised signatories from this group:{' '}
                    {requiredAuthorisedSignatory}
                  </Typography>
                </Grid>
                <Grid container pt={3}>
                  <Grid xs={12} alignSelf="center">
                    <WebViewDataRow
                      tableData={poaauthorisedsignatories}
                      tableHeader={poaAuthorisedheaders}
                      renderAdditionalRow={false}
                      tableHeaderCustomStyles={{
                        '.MuiTableCell-head': {
                          fontFamily: 'Poppins, sans-serif',
                          fontSize: 14,
                          fontWeight: 500,
                          color: 'primary.main',
                        },
                      }}
                      rowCustomStyles={{
                        '.MuiTableCell-root': {
                          py: '8px',
                          overflowWrap: 'anywhere',
                          padding: { xs: '10px', sm: '30px' },
                        },
                      }}
                      tableBodyCustomStyles={{
                        '.MuiTableRow-root': {
                          '&:last-child': {
                            borderBottom: 'none',
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                {groups.map((group, index) => {
                  return (
                    <React.Fragment key={group.id}>
                      <Grid container pt={3}>
                        <Typography>
                          Total No. of required authorised signatories from this group:{' '}
                          {group.threshold}
                        </Typography>
                      </Grid>
                      <Grid container pt={3}>
                        <Grid xs={12} alignSelf="center">
                          <WebViewDataRow
                            groupIndex={index}
                            tableData={group.groupsignatories.filter(
                              (_signatory) => _signatory.isActive
                            )}
                            tableHeader={headers}
                            renderAdditionalRow={false}
                            tableHeaderCustomStyles={{
                              '.MuiTableCell-head': {
                                fontFamily: 'Poppins, sans-serif',
                                fontSize: 14,
                                fontWeight: 500,
                                color: 'primary.main',
                              },
                            }}
                            rowCustomStyles={{
                              '.MuiTableCell-root': {
                                py: '8px',
                                overflowWrap: 'anywhere',
                                padding: { xs: '10px', sm: '30px' },
                              },
                            }}
                            tableBodyCustomStyles={{
                              '.MuiTableRow-root': {
                                '&:last-child': {
                                  borderBottom: 'none',
                                },
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  );
                })}
              </>
            )}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
