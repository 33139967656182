import { Box, Grid, Typography, Theme } from '@mui/material';
import { SubHeading } from '../investors/components';
import React from 'react';
import { Item, ItemDetails, Gridstyles } from './index';
import { Applicant } from '../../redux-store/types/api-types';
import { formatShortDate } from '../../utils/date';
import { SxProps } from '@mui/system';
import { applicantStatusMasters } from '../../utils/constant';

export const KeyValue = ({
  title,
  description,
  amount,
  sx = {},
}: {
  title: string | undefined;
  description?: string[] | string | number | boolean | null;
  amount?: number | null | undefined | string;
  sx?: SxProps<Theme>;
}): JSX.Element => {
  return (
    <Grid item xs={12} sm={6}>
      <Item sx={sx}>{title}</Item>
      <ItemDetails> {description ? description : '-'}</ItemDetails>
      {!!amount && amount !== 0 && <Item sx={{ pt: 0 }}> {amount}</Item>}
    </Grid>
  );
};

export default function ContributorDetails({
  applicants = [],
  modeOfHolding,
}: {
  applicants: Partial<Applicant>[];
  modeOfHolding: string;
}): JSX.Element {
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
                Contributor Details
              </Typography>
              <Typography
                sx={{
                  fontSize: 18,
                  fontWeight: 600,
                  color: 'text.secondary',
                }}>
                Mode of Holding:{' '}
                <Typography
                  component="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: '16px',
                    color: 'rgba(0, 0, 0, 0.6)',
                    letterSpacing: '0.4px',
                    ml: 0.5,
                    textTransform: 'capitalize',
                  }}>
                  {modeOfHolding || 'Single'}
                </Typography>
              </Typography>
            </Grid>
            {applicants.map((applicant, index: number) => {
              const title = [
                'First Applicant Details',
                'Second Applicant Details',
                'Third Applicant Details',
              ][index];
              return (
                <React.Fragment key={applicant.id}>
                  <SubHeading
                    sx={{
                      color: 'common.black',
                      letterSpacing: 0.5 + 'px',
                      padding: { xs: '10px 10px', sm: '10px 30px' },
                    }}>
                    {title}
                  </SubHeading>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={13}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      <KeyValue title={'Name'} description={applicant.name} />
                      <KeyValue title={'Gender'} description={applicant.gender} />
                      <KeyValue
                        title={'Date Of Birth'}
                        description={formatShortDate(applicant.dateOfBirth)}
                      />
                      <KeyValue
                        title={'Father/Spouse Name'}
                        description={applicant.fatherOrSpouseName}
                      />
                      <KeyValue title={'Mother Name'} description={applicant.motherName} />
                      <KeyValue title={'PAN'} description={applicant.panNo} />
                      <KeyValue
                        title={'Status'}
                        description={
                          applicant.status
                            ? applicantStatusMasters[applicant.status]
                            : applicant.status
                        }
                      />
                      <KeyValue title={'Nationality'} description={applicant.nationality} />
                      {index !== 0 && (
                        <KeyValue
                          title={'RelationShip With First Applicant'}
                          description={applicant.relationShipWithFirstApplicant}
                        />
                      )}
                    </Grid>
                  </Gridstyles>
                </React.Fragment>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
}
