import {
  Box,
  Button,
  Typography,
  Hidden,
  Grid,
  IconButton,
  Tooltip,
  Stack,
  Switch,
  Select,
  MenuItem,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useMemo, useState, ChangeEvent } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import {
  getAuthorisedSignatoryList,
  getRequiredForEsign,
  getRequiredForEsignType,
  setRequiredForEsign,
  updateAuthorisedSignatory,
} from '../../redux-store/actions/userManagement';
import {
  AddAuthorisedSignatoryRequestBody,
  AuthorisedSignatories,
} from '../../redux-store/types/api-types';
import { Link as RouterLink } from 'react-router-dom';
import { DataTable } from '../DataTable';
import { Location } from 'history';
import { ConfirmationDialog } from '../commonComponents';
import { AccessHeader } from './relationShipManagers';
import { getArrayLength } from '../../utils/utilityFunctions';

export default function AuthorisedSignatory({
  location,
}: {
  location: Location<{ distributorId: string }>;
}): JSX.Element {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [authorisedSignatoryData, setAuthorisedSignatoryData] = useState<AuthorisedSignatories[]>(
    []
  );
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(0);
  const { distributorId } = location.state || {};
  const [openDialog, setDialog] = useState<{
    authorisedSignatoryRowData: AddAuthorisedSignatoryRequestBody;
  } | null>(null);
  const [requiredEsignVal, setRequiredEsignVal] = useState<number[]>([]);
  const [RequireEsignValueRes, SetRequireEsignValueRes] = useState(0);

  const headers = [
    {
      header: `Name`,
      renderCell: (row: AuthorisedSignatories) => {
        return (
          <Typography
            variant="h5"
            sx={{
              color: '#61D1D6',
              textDecoration: 'none',
              fontWeight: 500,
              fontSize: '14px',
              ':hover': {
                color: 'primary.main',
              },
            }}
            component={RouterLink}
            to={{
              pathname: 'authorised-signatory-details',
              state: { authorisedSignatoryId: row.id },
            }}>
            {row.user?.firstName || 'N/A'}
          </Typography>
        );
      },
    },
    {
      header: 'Mobile Number',
      valueGetter: (row: AuthorisedSignatories) => row.user.phone || 'N/A',
    },
    {
      header: 'Email Id',
      valueGetter: (row: AuthorisedSignatories) => row.user.email || 'N/A',
    },
    {
      header: AccessHeader,
      renderCell: (row: AddAuthorisedSignatoryRequestBody): JSX.Element => {
        return (
          <>
            <Stack
              sx={{
                alignItems: { xs: 'baseline', sm: 'center' },
                '.MuiSwitch-root': {
                  paddingLeft: { xs: '0px', sm: '12px' },
                  width: { xs: '52px', sm: '58px' },
                },
                '.MuiSwitch-switchBase': { paddingLeft: { xs: '0px', sm: '9px' } },
              }}>
              <Switch
                sx={{ '.MuiSwitch-switchBase': { color: 'black' } }}
                checked={row.isActive}
                onChange={() => setDialog({ authorisedSignatoryRowData: row })}
              />
            </Stack>
          </>
        );
      },
    },
    // {
    //   header: '',
    //   renderCell: (row: AuthorisedSignatories): JSX.Element => {
    //     return (
    //       <>
    //         {row.isActive ? (
    //           <Tooltip title="Edit">
    //             <IconButton
    //               component={RouterLink}
    //               to={{
    //                 pathname: 'edit-authorised-signatory',
    //                 state: { authorisedSignatoryId: row.id },
    //               }}
    //               sx={{
    //                 ':hover': {
    //                   bgcolor: '#F4FCFC',
    //                 },
    //                 '&.Mui-disabled': {
    //                   cursor: 'pointer',
    //                   pointerEvents: 'all',
    //                 },
    //               }}>
    //               <EditIcon />
    //             </IconButton>
    //           </Tooltip>
    //         ) : (
    //           <Typography
    //             variant="h5"
    //             component={RouterLink}
    //             to={{
    //               pathname: 'edit-authorised-signatory',
    //               state: { authorisedSignatoryId: row.id },
    //             }}
    //             sx={{
    //               color: '#61D1D6',
    //               textDecoration: 'none',
    //               fontWeight: 500,
    //               fontSize: '14px',
    //               ':hover': {
    //                 color: 'primary.main',
    //               },
    //             }}>
    //             View Details
    //           </Typography>
    //         )}
    //       </>
    //     );
    //   },
    // },
  ];

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        if (distributorId) {
          const RequiredForEsign = (await dispatch(
            getRequiredForEsign(Number(distributorId))
          )) as unknown as getRequiredForEsignType;
          SetRequireEsignValueRes(RequiredForEsign.authorisedSignatoryThreshold);
          const res = (await dispatch(
            getAuthorisedSignatoryList({ distributorId: Number(distributorId) })
          )) as unknown as AuthorisedSignatories[];
          setRequiredEsignVal(
            getArrayLength(res.filter((isActiveData) => isActiveData.isActive).length)
          );
          setAuthorisedSignatoryData(res);
        } else {
          history.replace('/user-management');
        }
        if (!isComponentActive) {
          return;
        }
        setLoading(false);
      } catch (e) {
        console.error((e as Error).message);
      } finally {
        if (isComponentActive) setLoading(false);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  const handlePageChange = (event: ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  return (
    <>
      <Box sx={{ bgcolor: 'common.white', px: 4 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            pt: { xs: 2, sm: 7 },
            pb: { xs: 2, sm: 4 },
          }}>
          <Typography sx={{ fontSize: { xs: 15, sm: 23 }, fontWeight: 500 }}>
            List of Authorised Signatories
          </Typography>
          <Grid
            sx={{
              display: 'flex',
            }}
            alignItems="center">
            <Typography sx={{ color: 'primary.main', fontSize: '16px', ml: 3 }}>
              No. of Authorized signatories to esign:
            </Typography>
            <Box
              sx={{
                width: '100px',
                ml: { md: 1 },
                '.MuiOutlinedInput-root': {
                  marginTop: '0',
                  backgroundColor: 'rgba(97, 209, 214, 0.07)',
                  '&.MuiInputBase-root': {
                    '& .MuiSelect-select': {
                      padding: '7px 38px',
                      border: 'none',
                    },
                  },
                  '.MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                },
                '&.Mui-focused,&:hover': {
                  '.MuiOutlinedInput-notchedOutline': {
                    borderWidth: 'none',
                  },
                },
                svg: {
                  color: '#337FC9',
                },
              }}>
              <Select
                labelId="user-items"
                onChange={async (e) => {
                  try {
                    const resSetForEsign = await dispatch(
                      setRequiredForEsign({
                        authorisedSignatoryThreshold: Number(e.target.value),
                        distributorId: Number(distributorId),
                      }) as unknown as getRequiredForEsignType
                    );
                    SetRequireEsignValueRes(resSetForEsign.authorisedSignatoryThreshold);
                  } catch (e) {
                    console.error((e as Error).message);
                  }
                }}
                value={RequireEsignValueRes}
                sx={{
                  color: 'primary.main',
                  fontWeight: 500,
                }}>
                {requiredEsignVal.map((thresholdValue, ind) => (
                  <MenuItem
                    sx={{
                      borderBottom: '1px solid #ccc',
                      textAlign: 'center',
                      '&:last-child': { border: 'none' },
                      justifyContent: 'center',
                    }}
                    value={thresholdValue}
                    key={ind}>
                    {thresholdValue}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid>
          <Hidden only="xs">
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              sx={{
                color: 'white',
                fontWeight: 500,
                fontSize: 14,
                mr: 2.5,
                py: 0.5,
                px: 3,
              }}
              onClick={() =>
                history.push('add-authorised-signatory', {
                  distributorId: distributorId.toString(),
                })
              }>
              Add Authorised Signatory
            </Button>
          </Hidden>
          <Hidden smUp={true}>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              sx={{
                color: 'primary.main',
                fontWeight: 500,
                fontSize: 13,
                //mr: 2.5,
                py: 0.5,
                // px: 3,
                // whiteSpace: 'nowrap',
              }}
              onClick={() =>
                history.push('add-authorised-signatory', {
                  distributorId: distributorId.toString(),
                })
              }>
              Add Authorised Signatory
            </Button>
          </Hidden>
        </Box>
        <Grid item xs={12} sm={12} lg={12}>
          {useMemo(
            () => (
              <DataTable
                tableData={authorisedSignatoryData}
                tableHeader={headers}
                isLoading={isLoading}
                currentPage={currentPage}
                noOfPages={1}
                paginationAction={handlePageChange}
                renderAdditionalRow={true}
                tableBodyCustomStyles={{
                  '.MuiTableCell-root': {
                    borderBottom: 'none',
                  },
                }}
                tableHeaderCustomStyles={{
                  '&:last-child th': { border: 0 },
                  '.MuiTableCell-head': {
                    fontFamily: 'Poppins, sans-serif',
                    fontSize: 14,
                    fontWeight: 500,
                    color: 'primary.main',
                  },
                }}
              />
            ),
            [headers, isLoading, authorisedSignatoryData]
          )}
        </Grid>
      </Box>
      <ConfirmationDialog
        message={`Are you sure you want to ${
          openDialog?.authorisedSignatoryRowData.isActive ? 'disable' : 'enable'
        } the access to this user ?`}
        open={openDialog !== null}
        setOpen={() => setDialog(null)}
        onSave={async () => {
          setDialog(null);
          try {
            if (openDialog) {
              const updatedResponse = (await dispatch(
                updateAuthorisedSignatory(openDialog.authorisedSignatoryRowData.id as number, {
                  ...openDialog.authorisedSignatoryRowData,
                  isActive: !openDialog?.authorisedSignatoryRowData.isActive,
                })
              )) as unknown as AuthorisedSignatories;
              const authorisedSignatoriesUpdatedList = authorisedSignatoryData?.map(
                (authorisedSignatory) => {
                  if (Number(authorisedSignatory.id) === Number(updatedResponse.id)) {
                    return {
                      ...authorisedSignatory,
                      ...updatedResponse,
                    };
                  }
                  return authorisedSignatory;
                }
              );
              setRequiredEsignVal(
                getArrayLength(
                  authorisedSignatoriesUpdatedList.filter((isActiveData) => isActiveData.isActive)
                    .length
                )
              );
              setAuthorisedSignatoryData(authorisedSignatoriesUpdatedList);
            }
          } catch (e) {
            console.error((e as Error).message);
          }
        }}
        onCancel={() => setDialog(null)}
      />
    </>
  );
}
